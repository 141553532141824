import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import axios from "axios";
import { useSnackbar } from 'notistack';

import { useStyles } from "./style";
import { useCart } from "../Cart/useCart";

const FormModal = ({ open, close }: Props) => {
    const classes = useStyles();

    const { cartItems, clearCart } = useCart();

    const [ first, setFirst ] = useState("");
    const [ last, setLast ] = useState("");
    const [ phone, setPhone ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ company, setCompany ] = useState("");
    const [ notes, setNotes ] = useState("");

    const { enqueueSnackbar } = useSnackbar();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      if (event.target.id === 'first') {
        setFirst(event.target.value);
      }
      else if (event.target.id === 'last') {
        setLast(event.target.value);
      }
      else if (event.target.id === 'email') {
        setEmail(event.target.value);
      }
      else if (event.target.id === 'phone') {
        setPhone(event.target.value);
      }
      else if (event.target.id === 'company') {
        setCompany(event.target.value);
      }
      else if (event.target.id === 'notes') {
        setNotes(event.target.value);
      }
    }

    const validateAndSubmit = async (): Promise<void> => {
      if (first === "" || last === "" || email === "" || phone === "") {
        return;
      }

      try {
        await axios.post('/api/quote', {
          firstName: first,
          lastName: last,
          email,
          phone,
          company,
          note: notes,
          cart: cartItems.map(item => {
            return {
              partNumber: item.inventoryItem.partno,
              manufacturer: item.inventoryItem.manufacturer,
              quantity: item.quantity
            };
          })
        });

        clearCart();

        close();

        enqueueSnackbar('Quote request successfully submitted.', { 
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          }
        });
      }
      catch (err) {
        console.error(err);

        close();

        enqueueSnackbar('There was a problem submitting the quote request. Please call us for your quote.', { 
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          }
        });
      }
    }

    return (
      <Modal open={open}
             onClose={close}
             color='primary'
             title="Request a Quote Form"
             >
        <form method="POST" className={classes.form}>
          <Grid container direction='column' spacing={4}>
            <Grid item xs={12} >
              <Typography variant="h4">Get a Quote</Typography>
              <Typography variant="subtitle1">Please enter the requested information below. We will reach out to you within a few business days. Your information will never be shared.</Typography>
            </Grid>
            <Grid item xs={12} className={classes.formGroup}>
              <TextField required id="first" label="First Name" placeholder="First Name" value={first}  onChange={handleChange} error={first === ""} helperText="First Name required" />
              <TextField required id="last" label="Last Name" placeholder="Last Name" value={last} onChange={handleChange} error={last === ""} helperText="Last Name required"/>
            </Grid>
            <Grid item xs={12} className={classes.formGroup}>
              <TextField required id="email" label="Email" placeholder="abc@xyz.com" value={email} onChange={handleChange} error={email === ""} helperText="Email required"/>
              <TextField required id="phone" label="Phone" placeholder="NNN-NNN-NNNN" value={phone} onChange={handleChange} error={phone === ""} helperText="Phone required" />
            </Grid>
            <Grid item xs={12} className={classes.formGroup}>
              <TextField id="company" label="Company" placeholder="Company" value={company} onChange={handleChange} />
              <TextField id="notes" label="Note" placeholder="Note" value={notes} onChange={handleChange} />
            </Grid>
            <Grid item xs={12}>
             <Button color='primary' onClick={validateAndSubmit} variant='contained'>Submit</Button>
            </Grid>
          </Grid>
        </form>
      </Modal>
    );
}

type Props = {
    open: boolean;
    close(): void;
}

export default FormModal;
