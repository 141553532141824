import { QueryParamConfig } from 'use-query-params';

export enum InventoryItemFields {
  ID = 'id',
  HECI = 'heci_clei',
  PARTNO = 'partno',
  MANUFACTURER = 'manufacturer',
  DESCRIPTION = 'description',
  QUANTITY = 'qty',
}

export interface InventoryItem {
    [InventoryItemFields.ID]: number;
    [InventoryItemFields.HECI]: number;
    [InventoryItemFields.PARTNO]: string;
    [InventoryItemFields.DESCRIPTION]: string;
    [InventoryItemFields.MANUFACTURER]: string;
    [InventoryItemFields.QUANTITY]: number;
}

export interface CartItem {
    inventoryItem: InventoryItem;
    quantity: number;
}

export interface SearchCriteria {
    [InventoryItemFields.HECI]: QueryParamConfig<string | null | undefined, string | null | undefined>;
    [InventoryItemFields.PARTNO]: QueryParamConfig<string | null | undefined, string | null | undefined>;
    [InventoryItemFields.DESCRIPTION]: QueryParamConfig<string | null | undefined, string | null | undefined>;
    [InventoryItemFields.MANUFACTURER]: QueryParamConfig<string | null | undefined, string | null | undefined>;
}

export interface Pager {
    page: number;
    pageSize: number;
}
