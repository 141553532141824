import React, { useMemo } from "react";
import {
  DataGrid,
  GridColDef,
  GridSortModel,
} from "@material-ui/data-grid";
import IconButton from "@material-ui/core/IconButton";
import { AddShoppingCart } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import axios from "axios";
import { NumberParam, useQueryParam, withDefault } from "use-query-params";
import { isEqual } from 'lodash';

import { InventoryItem, InventoryItemFields } from "../../types";
import { useCart } from "../Cart/useCart";
import { useQuery } from "react-query";
import useSearchGrid from "./useSearchGrid";
import { useCallback } from "react";
import { useSearchBar } from "../SearchBar/useSearchBar";
import { CustomPagination } from "./CustomPagination";

const SearchGrid = () => {
  const searchQueryKey: string = "search-data";
  let [pageSize, setPageSize] = useQueryParam("pageSize", withDefault(NumberParam, 10));

  const { searchCriteriaQueryString } = useSearchBar();

  const { addItemToCart } = useCart();
  const { gridCriteria, updatePage, updateSort } = useSearchGrid();

  const { isLoading, error, data } = useQuery([searchQueryKey, gridCriteria.page, gridCriteria.sort, searchCriteriaQueryString, pageSize], useCallback(async () => {
    const sortString = (gridCriteria.sort && `&sort=${gridCriteria.sort}`) ?? '';
    const searchQuery = (searchCriteriaQueryString !== '') ? `&q=${searchCriteriaQueryString}` : '';

    const response = await axios.get(`/api/search?page=${gridCriteria.page}&pageSize=${pageSize}${sortString}${searchQuery}`);

    return response.data;
  }, [gridCriteria.page, gridCriteria.sort, searchCriteriaQueryString, pageSize]), { keepPreviousData: true });

  const columns: GridColDef[] = useMemo(() => [
    { flex: 2, field: InventoryItemFields.HECI, headerName: "HECI_CLEI" },
    { flex: 2, field: InventoryItemFields.PARTNO, headerName: "Part Number" },
    { flex: 5, field: InventoryItemFields.DESCRIPTION, headerName: "Description", sortable: false },
    { flex: 2, field: InventoryItemFields.MANUFACTURER, headerName: "Manufacturer" },
    { flex: 1.75, field: InventoryItemFields.QUANTITY, headerName: "Quantity", type: "number" },
    {
      flex: 0.75,
      field: "",
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title="Add to Cart" placement="right">
            <span>
              <IconButton
                size="small"
                onClick={() => addItemToCart(params.row as InventoryItem)}
                disabled={parseInt(params.row.qty) <= 0}
              >
                <AddShoppingCart />
              </IconButton>
            </span>
          </Tooltip>
        );
      },
    },
  ], [addItemToCart]);

  const handlePageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  }

  const handlePageChange = useCallback((page: number): void => {
    if (gridCriteria.page === page) return;

    updatePage(page);
  }, [gridCriteria.page, updatePage]);

  const handleSortChange = useCallback((model: GridSortModel): void => {
    if (isEqual(gridCriteria.sortModel, model)) return;

    updateSort(model);
  }, [gridCriteria.sortModel, updateSort]);

  const rows = (data && data.results as Array<InventoryItem>) ?? new Array<InventoryItem>();
  const total = (data && data.total) ?? 0;

  return (
    <DataGrid
      autoHeight
      disableColumnFilter
      rows={rows}
      rowCount={total}
      columns={columns}
      paginationMode="server"
      pageSize={pageSize}
      onPageSizeChange={handlePageSizeChange}
      page={gridCriteria.page}
      onPageChange={handlePageChange}
      sortingMode="server"
      sortModel={gridCriteria.sortModel}
      onSortModelChange={handleSortChange}
      loading={isLoading}
      error={error}
      components={{
        Pagination: () => (<CustomPagination page={gridCriteria.page} count={total} />),
      }}
    />
  );
};

export default SearchGrid;
