import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { asyncWithConfigProvider, loadConfig } from '@kikoda/delivery-hooks';
import App from './components/App';
import reportWebVitals from './reportWebVitals';

import './index.css';

export interface WebConfig {
  additionalConfig: {
    platform: {
      backend: {
        endpoint: string;
      };
    };
  };
};

const asyncRender = async (host = '') => {
  const config = await loadConfig<WebConfig>(host);
  const ConfigProvider = await asyncWithConfigProvider<WebConfig>({ host, config });

  axios.defaults.baseURL = config.additionalConfig.platform.backend.endpoint;

  ReactDOM.render(
    <React.StrictMode>
      <ConfigProvider>
        <App />
      </ConfigProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

asyncRender();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
