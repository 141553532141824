import { CartItem, InventoryItem } from "../../types";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useCallback } from "react";

export const useCart = () => {
  const [cartItems, setCartItems] = useLocalStorage("cart", new Array<CartItem>());

  const addItemToCart = useCallback((item: InventoryItem) => {
    if (cartItems.find((i) => i.inventoryItem.id === item.id)) return;

    const newItem = {
      inventoryItem: item,
      quantity: 1,
    } as CartItem;

    setCartItems([...cartItems, newItem]);
  }, [cartItems, setCartItems]);

  const updateCartItemQuantity = useCallback((id: number, quantity: number) => {
    const newItems = cartItems.map(i => {
      if(i.inventoryItem.id !== id) return i;

      i.quantity = quantity;
      return i;
    })

    setCartItems(newItems);
  }, [cartItems, setCartItems]);

  const removeItemFromCart = useCallback((id: number) => {
    const newItems = cartItems.filter(i => i.inventoryItem.id !== id);
    setCartItems(newItems);
  }, [cartItems, setCartItems]);

  const clearCart = useCallback(() => {
    setCartItems(new Array<CartItem>());
  }, [setCartItems]);

  return {
    cartItems,
    addItemToCart,
    updateCartItemQuantity,
    removeItemFromCart,
    clearCart
  };
};
