import React, { useState } from "react";

import { ThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { theme } from "../../theme";
import { useStyles } from "./styles";

import Header from "../Header";
import SearchGrid from "../SearchGrid";
import Cart from "../Cart";
import FormModal from "../FormModal";
import SearchBar from "../SearchBar";
import Typography from "@material-ui/core/Typography";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { QueryClientProvider, QueryClient } from "react-query";
import { SnackbarProvider } from 'notistack';
import { Container } from "@material-ui/core";

const queryClient = new QueryClient();

function App() {
  const classes = useStyles();

  const [isOpen, showModal] = useState(false);

  const openModal = () => {
    showModal(true);
  };

  const closeModal = () => {
    showModal(false);
  };

  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <QueryClientProvider client={queryClient}>
              <Header />
              <Container maxWidth='xl'>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  justifyContent="space-between"
                  className={classes.content}
                >
                  <Grid
                    container
                    item
                    sm={12}
                    md={9}
                    lg={10}
                    spacing={4}
                    direction="column"
                  >
                    <Grid item>
                      <Typography variant="h4">Search Our Inventory</Typography>
                    </Grid>
                    <Grid item>
                      <SearchBar />
                    </Grid>
                    <Grid item>
                      <SearchGrid />
                    </Grid>
                  </Grid>
                  <Grid container item sm={12} md={3} lg={2}>
                    <Cart openModal={openModal} />
                  </Grid>
                </Grid>
              </Container>
              <FormModal open={isOpen} close={closeModal} />
            </QueryClientProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </QueryParamProvider>
    </Router>
  );
}

export default App;
