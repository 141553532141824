import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      position: "absolute",
      width: 600,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    },
    formGroup: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: 5,
      "& > *": {
        flexGrow: 1,
        margin: 5
      }
    }
  })
);
