import React, { FunctionComponent } from "react";
import AppBar from "@material-ui/core/AppBar";
import { Button } from "@material-ui/core";
import PhoneIcon from '@material-ui/icons/Phone';

import logo from "../../images/logo.png";
import { useStyles } from './styles';

const Header: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <AppBar position="static" color="transparent" className={classes.header}>
      <div className={classes.wrapper}>
        <img src={logo} alt="E and M Communications Logo" className={classes.image} />
        <Button className={classes.call} href="tel:8508780875"><PhoneIcon color="primary" />(850) 878-0875</Button>
        <Button variant="contained" color="secondary" className={classes.visit} href="http://www.emcommunications.com">
          Visit Our Site
        </Button>
      </div>
    </AppBar>
  );
};

export default Header;
