import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  itemWrapper: {
    width: "100%"
  },
  containerWrapper: {
    position: "sticky",
    top: "1rem"
  },
  list: {
    color: "rgba(0, 0, 0, 0.87)",
    border: "1px solid rgba(224, 224, 224, 1)",
    borderRadius: "4px"
  },
  submitButton: {
    width: "100%"
  },
  clearButtonContainer: {
    textAlign: 'right'
  }
});
