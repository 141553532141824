import React, { ChangeEvent, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useSearchBar } from "./useSearchBar";
import Button from "@material-ui/core/Button";
import { useStyles } from "./style";
import { InventoryItemFields } from "../../types";

const SearchBar = () => {
  const classes = useStyles();
  const { clearSearch, searchCriteria, updateSearch } = useSearchBar();

  const [ { heci_clei, partno, manufacturer, description }, setInputState ] = useState({
    heci_clei: searchCriteria.heci_clei === undefined || searchCriteria.heci_clei === null ? "" : searchCriteria.heci_clei,
    partno: searchCriteria.partno === undefined || searchCriteria.partno === null ? "" : searchCriteria.partno,
    manufacturer: searchCriteria.manufacturer === undefined || searchCriteria.manufacturer === null ? "" : searchCriteria.manufacturer,
    description: searchCriteria.description === undefined || searchCriteria.description === null ? "" : searchCriteria.description,
  });

  // init a timeout for the input delay
  let timeout: number | undefined = undefined;

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newSearchCriteria = searchCriteria;
    const inputId = event.target.id;
    const newValue = event.target.value;

    setInputState((state => ({ ...state, [inputId]: newValue })));

    switch(inputId) {
      case InventoryItemFields.HECI:
        newSearchCriteria.heci_clei = newValue;
        break;
      case InventoryItemFields.DESCRIPTION:
        newSearchCriteria.description = newValue;
        break;
      case InventoryItemFields.PARTNO:
        newSearchCriteria.partno = newValue;
        break;
      case InventoryItemFields.MANUFACTURER:
        newSearchCriteria.manufacturer = newValue;
        break;
      default:
        throw Error(`${inputId} case not handled`);
    }

    clearTimeout(timeout)
    timeout = setTimeout(() => updateSearch(newSearchCriteria), 500) as unknown as number;
  };

  const handleClearSearch = () => {
    setInputState({
      heci_clei: '',
      description: '',
      partno: '',
      manufacturer: '',
    });

    clearSearch();
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={1} md={6} sm={12}>
        <TextField
          id={InventoryItemFields.HECI}
          label="HECI/CLEI"
          placeholder="HECI/CLEI"
          value={heci_clei}
          onChange={handleChange}
          className={classes.textbox}
        />
      </Grid>
      <Grid item lg={2} md={6} sm={12}>
        <TextField
          id={InventoryItemFields.PARTNO}
          label="Part Number"
          placeholder="Part Number"
          value={partno}
          onChange={handleChange}
          className={classes.textbox}
        />
      </Grid>
      <Grid item lg={5} md={6} sm={12}>
        <TextField
          id={InventoryItemFields.DESCRIPTION}
          label="Description"
          placeholder="Description"
          value={description}
          onChange={handleChange}
          className={classes.textbox}
        />
      </Grid>
      <Grid item lg={2} md={6} sm={12}>
        <TextField
          id={InventoryItemFields.MANUFACTURER}
          label="Manufacturer"
          placeholder="Manufacturer"
          value={manufacturer}
          onChange={handleChange}
          className={classes.textbox}
        />
      </Grid>
      <Grid item lg={2} md={12} className={classes.btnContainer}>
        <Button
          variant="outlined"
          color="secondary"
          className={classes.clearButton}
          onClick={handleClearSearch}
        >
          Clear Search
        </Button>
      </Grid>
    </Grid>
  );
};

export default SearchBar;
