import React, { ChangeEvent } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "./styles";
import Button from "@material-ui/core/Button";
import { useCart } from "./useCart";

export default function Cart({ openModal }: Props) {
  const classes = useStyles();
  const { cartItems, removeItemFromCart, updateCartItemQuantity, clearCart } = useCart();

  const quantityChange = (event: ChangeEvent<HTMLInputElement>, inventoryItemId: number): void => {
    let newQuantity: number = parseInt(event.target.value);

    if (newQuantity < 1) {
      newQuantity = 1;
    }

    updateCartItemQuantity(inventoryItemId, newQuantity);
  }

  return (
    <Grid item className={classes.itemWrapper}>
      <Grid container direction="column" spacing={2} className={classes.containerWrapper}>
        <Grid item>
          <Typography variant="h4">Cart</Typography>
        </Grid>
        <Grid item container>
          <Grid item xs={6}>
            <Typography>Selected Items:</Typography>
          </Grid>
          <Grid item xs={6} className={classes.clearButtonContainer}>
            <Button
              variant="text"
              color="secondary"
              size="small"
              onClick={clearCart}
              disabled={cartItems.length === 0}
            >
              Clear Cart
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <List dense={true} className={classes.list}>
            {cartItems.length === 0 && (
              <Typography style={{ padding: '0 16px' }}>No items selected.</Typography>
            )}
            {cartItems.length > 0 &&
              cartItems.map((cartItem) => (
                <ListItem key={cartItem.inventoryItem.id}>
                  <Grid container direction='row' spacing={1}>
                    <Grid item lg={12} xl={7}>
                      <ListItemText
                        primary={cartItem.inventoryItem.partno}
                        secondary={cartItem.inventoryItem.manufacturer}
                      />
                    </Grid>
                    <Grid item lg={4} xl={3}>
                      <ListItemText>
                        <TextField label='Qty' type="number" value={cartItem.quantity} onChange={e => quantityChange(e as ChangeEvent<HTMLInputElement>, cartItem.inventoryItem.id)} />
                      </ListItemText>
                    </Grid>
                    <Grid item lg={8} xl={1}>
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          size="small"
                          aria-label="delete"
                          onClick={() =>
                            removeItemFromCart(cartItem.inventoryItem.id)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
          </List>
        </Grid>
        <Grid item>
          <Button
            onClick={openModal}
            color="primary"
            type="submit"
            size="large"
            variant="contained"
            className={classes.submitButton}
          >
            Get a Quote
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

type Props = {
  openModal(): void;
};
