import { useCallback, useMemo } from "react";
import {
  useQueryParams,
  StringParam,
  QueryParamConfig,
  DecodedValueMap,
} from "use-query-params";
import { InventoryItemFields } from "../../types";

export const useSearchBar = () => {
  const [searchCriteria, setCriteria] = useQueryParams({
    [InventoryItemFields.HECI]: StringParam,
    [InventoryItemFields.PARTNO]: StringParam,
    [InventoryItemFields.DESCRIPTION]: StringParam,
    [InventoryItemFields.MANUFACTURER]: StringParam,
  });

  const searchCriteriaQueryString = Object.keys(searchCriteria)
    //@ts-ignore
    .filter(key => searchCriteria[key] !== undefined)
    //@ts-ignore
    .map(key => `${key}:${searchCriteria[key]}`)
    .join(',');

  const updateSearch = useCallback((
    searchCriteria: DecodedValueMap<{
      [InventoryItemFields.HECI]: QueryParamConfig<
        string | null | undefined,
        string | null | undefined
      >;
      [InventoryItemFields.PARTNO]: QueryParamConfig<
        string | null | undefined,
        string | null | undefined
      >;
      [InventoryItemFields.DESCRIPTION]: QueryParamConfig<
        string | null | undefined,
        string | null | undefined
      >;
      [InventoryItemFields.MANUFACTURER]: QueryParamConfig<
        string | null | undefined,
        string | null | undefined
      >;
    }>
  ) => {
    let key: keyof typeof searchCriteria;
    for (key in searchCriteria) {
      if(searchCriteria[key] === "")
        searchCriteria[key] = undefined;
    }

    setCriteria(searchCriteria);
  }, [setCriteria]);

  const clearSearch = useCallback(() => {
    setCriteria({
      [InventoryItemFields.HECI]: undefined,
      [InventoryItemFields.DESCRIPTION]: undefined,
      [InventoryItemFields.MANUFACTURER]: undefined,
      [InventoryItemFields.PARTNO]: undefined
    });
  }, [setCriteria]);

  return useMemo(() => ({
    searchCriteria,
    searchCriteriaQueryString,
    updateSearch,
    clearSearch,
  }), [searchCriteria, searchCriteriaQueryString, updateSearch, clearSearch]);
};
