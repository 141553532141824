import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  header:{
    height: 'auto'
  },
  wrapper: {
    [theme.breakpoints.down('md')]: {
      width: '95vw',
      flexWrap: 'wrap',
      justifyContent: "center",
    },
    width: 1200,
    height: "inherit",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "auto",
    padding: "1.5em 0 1.5em 0",
    alignItems: "center"
  },
  image: {
    height: 74
  },
  visit: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1em',
    },
    textTransform: "uppercase",
    color: "white",
    fontSize: "1.4em",
    fontWeight: 700,
    padding: "0.575em 0.85em 0.575em 0.85em"
  },
  call: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1em',
    },
    fontSize: "1.6em",
    fontWeight: 700,
    '&hover': {
      textDecoration: "none",
    }
  }
}));