import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
    palette: {
      primary: {
        main: "#5b0b0c",
      },
      secondary: {
        main: "#cb9731",
      },
    },
  });
