import { useCallback, useMemo, useState } from "react";
import { GridSortDirection, GridSortItem, GridSortModel } from "@material-ui/data-grid";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";

const sortStringToModel = (sortString: string | null | undefined): GridSortModel => {
  if (!!!sortString) return [] as GridSortModel;

  return sortString.split(',').map((s: string) => ({
    field: s.split(':')[0],
    sort: s.split(':')[1] as GridSortDirection,
  }));
};

const sortModelToString = (model: GridSortModel): string => model.map((item: GridSortItem) => {
  return `${item.field}:${item.sort}`;
}).join(',');

const useSearchGrid = () => {
  const [page, setPage] = useQueryParam("page", NumberParam);
  const [sort, setSort] = useQueryParam("sort", StringParam);

  const initialSort = useCallback(() => sortStringToModel(sort), [sort])

  const [sortModel, setSortModel] = useState<GridSortModel>(useMemo(initialSort, [sort, initialSort]));

  const updatePage = useCallback((newPage: number) => setPage(newPage), [setPage]);

  const updateSort = useCallback((model: GridSortModel) => {
    setSort((!!model.length) ? sortModelToString(model) : undefined);
    setSortModel(model);
  }, [setSort, setSortModel]);

  return useMemo(() => ({
    gridCriteria: {
      page: page === null || page === undefined ? 0 : page,
      sort,
      sortModel,
    },
    updatePage,
    updateSort,
  }), [page, sort, sortModel, updatePage, updateSort]);
};

export default useSearchGrid;
